function validate(form) {
    const validation = new JustValidate(form);

    validation
        .addField(`${form} .input-name`, [
            {
                rule: 'minLength',
                value: 3,
            },
            {
                rule: 'maxLength',
                value: 30,
            },
            {
                rule: 'required',
                value: true,
                errorMessage: 'Enter your name!'
            }
        ])
        .addField(`${form} .input-telegram-whatsapp`, [
            {
                rule: 'required',
                value: true,
                errorMessage: 'Required field!',
            },
        ])
        .addField(`${form} .input-website`, [
            {
                rule: 'minLength',
                value: 3,
            },
            {
                rule: 'required',
                value: true,
                errorMessage: 'Provide a link to a website!'
            }
        ])
        .addField(`${form} .select-price`, [
            {
                rule: 'required',
                value: true,
                errorMessage: 'Please select an amount!'
            }
        ]).onSuccess((event) => {

            let formData = new FormData(event.target);
            let xhr = new XMLHttpRequest();

            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        document.location.href = "thank-page.html";
                    }
                }
            };

            xhr.open('POST', 'mail.php', true);
            xhr.send(formData);

            event.target.reset();
        });
}

validate('.form-modal');

if (document.querySelector('.form-contact')) {
    validate('.form-contact');
}









