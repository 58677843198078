function tabs(tabTrigger, tabTriggerWrap, tabContent) {
    const tabBox = document.querySelectorAll(tabContent);
    const tabTop = document.querySelector(tabTriggerWrap);
    const tabBtn = document.querySelectorAll(tabTrigger);

    if (tabTop) {
        tabTop.addEventListener('click', (event) => {

            let tabClass = event.target.getAttribute("data-tab");

            if (tabClass) {
                tabBtn.forEach(item => {
                    item.classList.remove('active');
                });

                event.target.classList.add('active');

                tabBox.forEach(elem => {
                    elem.classList.remove('hide');
                    if (!elem.classList.contains(tabClass) && tabClass) {
                        elem.classList.add('hide');
                    }
                });
            }

        });
    }
}

tabs('.project-tabs-item', '.project-tabs', '.project-content');