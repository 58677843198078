document.addEventListener('DOMContentLoaded', function () {






    if ($(window).width() <= 767) {
        const awardsSwiper = new Swiper(".awards-swiper", {
            slidesPerView: 2,
            grid: {
                rows: 2,
            },
            spaceBetween: 40,
            speed: 700,
            pagination: {
                el: ".awards .swiper-pagination",
                clickable: true,
            },
            breakpoints: {
                767: {
                    loop: true,
                },
                300: {
                    loop: false,
                },
            },
        });
    } else {
        $('.awards .awards__wrapper').marquee({
            duration: 30000,
            startVisible: true,
            duplicated: true,
            delayBeforeStart: 0,
            pauseOnHover: true,
            gap: 25
        });
    }

    if ($(window).width() <= 767) {
        const swiper = new Swiper(".services-swiper", {
            slidesPerView: 2,
            spaceBetween: 30,
            grid: {
                rows: 2,
            },
            speed: 600,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            breakpoints: {
                600: {
                    slidesPerView: 2,
                },
                300: {
                    slidesPerView: 1,
                },
            },
        });
    }



    const reviewsSwiper = new Swiper(".reviews-swiper", {
        slidesPerView: 3,
        spaceBetween: 60,
        loop: true,
        speed: 600,
        slideCenter: true,
        pagination: {
            el: ".reviews .swiper-pagination",
            clickable: true,
        },
        navigation: {
            nextEl: ".reviews .swiper-button-next",
            prevEl: ".reviews .swiper-button-prev",
        },
        breakpoints: {
            1080: {
                slidesPerView: 3,
            },
            700: {
                slidesPerView: 2,
            },
            300: {
                slidesPerView: 1,
            },
        },
    });

    const trustSwiper = new Swiper(".trust-swiper", {
        slidesPerView: 4,
        spaceBetween: 30,
        loop: true,
        speed: 600,
        pagination: {
            el: ".trust .swiper-pagination",
            clickable: true,
        },
        navigation: {
            nextEl: ".trust .swiper-button-next",
            prevEl: ".trust .swiper-button-prev",
        },
        breakpoints: {
            1150: {
                slidesPerView: 4,
            },
            900: {
                slidesPerView: 3,
            },
            550: {
                slidesPerView: 2,
            },
            300: {
                slidesPerView: 1,
            },
        },
    });








});