'use strict';

document.addEventListener('DOMContentLoaded', function () {

    function ipUser(input) {
        let ip = document.querySelectorAll('.ip-user');

        ip.forEach(item => {
            item.value = input;
        });

    }

    fetch('https://ipapi.co/json/')
        .then(d => d.json())
        .then(d => ipUser(d.ip));






});